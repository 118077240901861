@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
$mobile-340: 340px;
$mobile-420: 420px;
$mobile-600: 650px;
$mobile-500: 550px;
$mobile-700: 768px;
$mobile-800: 800px;
$mobile-1024: 1024px;
$mobile-1240: 1240px;
$main-color: #041c44;
$main-color-light: #424b5a;
$bright-red: red;
$danger-color: darkred;
$border-color: #e5e5ed;
$border-color2: #c2d1d9;

@mixin Pop {
  font-family: 'Poppins', sans-serif;
}

@mixin Label {
  display: flex;
  align-items: center;
  cursor: pointer;
  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: rgba(130, 138, 145, 0.75);
    padding-right: 5px;
  }
  svg {
    color: rgba(130, 138, 145, 0.75);
    font-size: 11px;
    margin-right: 5px;
  }
  h4 {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #424b5a;
  }
  @include mobile-500 {
    width: 100%;
    padding-bottom: 20px;
  }
}
@mixin ButtonBold {
  background-color: #041c44;
  width: 160px;
  height: 50px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  border: none;
  opacity: 1;
  transition: opacity 1s;
  &:hover {
    opacity: 0.6;
  }
}
@mixin ButtonDelete {
  background-color: darkred;
  width: 160px;
  height: 50px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  border: none;
  opacity: 1;
  transition: opacity 1s;
  &:hover {
    opacity: 0.6;
  }
}
@mixin ButtonLight {
  background: none;
  width: 160px;
  height: 50px;
  color: #424b5a;
  font-weight: 400;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border: 0.5px solid rgba(130, 138, 145, 0.75);
  border-radius: 8px;
  opacity: 1;
  transition: opacity 1s;
  &:hover {
    opacity: 0.6;
  }
}
@mixin ButtonSky {
  background-color: #c2d1d9;
  min-width: 160px;
  height: 50px;
  color: #041c44;
  font-weight: 400;
  padding: 0 10px;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  border-radius: 8px;
  border: none;
  opacity: 1;
  transition: opacity 1s;
  &:hover {
    opacity: 0.6;
  }
}
@mixin MainModal {
  position: fixed;
  z-index: 15;
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  align-items: center;
  .modal {
    background: #fbfbfb;
    width: 90%;
    max-width: 600px;
    box-shadow: 3.11243px 3.11243px 20.7495px rgba(130, 140, 204, 0.6);
    border-radius: 10.3748px;
    padding: 30px;
    max-height: 95vh;
    overflow-y: auto;
    .close {
      text-align: right;
      svg {
        color: $main-color;
        cursor: pointer;
        font-size: 25px;
      }
    }
    .tag {
      margin-bottom: 32px;
      border-radius: 8px;
      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 45px;
        color: $main-color;
      }
      p {
        font-size: 12px;
        color: $main-color;
      }
    }
    .form {
      form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
      .card {
        width: 47%;
        margin-bottom: 26px;
        @include mobile-500 {
          width: 100%;
        }
        &.dif {
          width: 100%;
        }
        p {
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: $main-color;
          padding-bottom: 8px;
        }
        input,
        select {
          width: calc(100% - 34px);
          padding: 0px 17px;
          height: 44px;
          font-weight: 400;
          font-size: 12px;
          line-height: 24px;
          color: #424b5a;
          border: 1px solid $border-color2;
          border-radius: 8px;
          outline: none;
          position: relative;
          display: inline-block;
          &.css-5kkxb2-requiredInput-RequiredInput {
            display: none;
          }
          &.active {
            border: 1px solid red;
          }
          &::placeholder {
            opacity: 0.5;
          }
          @include Pop;
        }
        textarea {
          width: calc(100% - 34px);
          padding: 10px 17px;
          font-weight: 400;
          font-size: 12px;
          color: #424b5a;
          border: 1px solid $border-color2;
          border-radius: 8px;
          outline: none;
          resize: none;
        }
        .css-b62m3t-container {
          .css-13cymwt-control {
            border: 1px solid $border-color2;
            border-radius: 8px;
            padding: 0px 17px;
            height: 44px;

            input {
              height: 100%;
              &::placeholder {
                font-size: 12px;
              }
            }
            svg {
              margin-top: 0;
              color: $main-color;
            }
          }
          .css-1dimb5e-singleValue,
          .css-1jqq78o-placeholder {
            font-size: 12px;
          }
          .css-1fdsijx-ValueContainer {
            padding-left: 0;
          }
          .css-t3ipsp-control {
            height: 44px;
            input {
              height: 100%;
            }
            svg {
              margin-top: 0;
              color: $main-color;
            }
          }
        }
        .search {
          input {
            padding: 8px 12px;
            margin-bottom: 5px;
          }
          button {
            font-size: 12px;
            border-radius: 8px;
            border: none;
            padding: 8px 12px;
            background: #0a810a7c;
            color: #fff;
            margin-bottom: 10px;
          }
          h3 {
            font-weight: 600;
            color: #6c757d;
            padding-bottom: 5px;
            font-size: 12px;
            span {
              color: #000;
            }
          }
        }
        select {
          width: 100%;
          option {
            text-transform: capitalize;
          }
        }
        .autocomplete {
          position: relative;
          .autocomplete-dropdown {
            position: absolute;
            top: 100%;
            z-index: 1;
            left: 0;
            width: 100%;
            box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
            background-color: #fff;
          }

          .autocomplete-search-results-list {
            margin: 0;
            padding: 0;
            list-style: none;
          }

          .autocomplete-search-result {
            padding: 0.75rem 1rem;
            cursor: pointer;
          }

          .autocomplete-search-result:hover,
          .autocomplete-search-result:focus,
          .autocomplete-search-result:active {
            background-color: #f9fafc;
          }
        }
        .selet-box {
          display: flex;
          align-items: center;
          input {
            width: unset;
          }
          label {
            padding-left: 10px;
          }
        }
        svg {
          color: red;
          margin-top: 10px;
          font-size: 25px;
        }
        span {
          color: red;
          font-size: 12px;
        }
      }
      .cardX {
        width: 44%;
        padding-bottom: 10px;
        @include mobile-500 {
          width: 100%;
        }
        .lef {
          h4 {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            color: $main-color;
            padding-bottom: 8px;
          }
        }
        .rig {
          border: 1px solid rgba(19, 19, 19, 0.25);
          border-radius: 3px;
          padding: 11px 17px;
          &.active {
            border: 1px solid red;
          }
          .butz {
            border-radius: 4px;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-right: 15px;
            color: $main-color;
            svg {
              margin-right: 8px;
              font-size: 14px;
            }
            p {
              font-size: 12px;
              color: rgba(61, 90, 128, 0.5);
            }
          }
          input {
            display: none;
          }
        }
        .flow {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .sel {
            font-size: 11px;
            line-height: 24px;
            color: #424b5a;
            padding-bottom: 5px;
            list-style-type: disc;
            padding-right: 5px;
          }
          svg {
            color: red;
            font-size: 12px;
          }
        }
      }
    }
    .button {
      float: right;
      margin-top: 10px;
      font-size: 12px;
      text-transform: uppercase;
      background: linear-gradient(285.6deg, #092d4e -32.89%, #0b8cee 90.01%);
      border: none;
      border-radius: 7px;
      color: #fff;
      padding: 6px 10px;
    }
    .buttons {
      text-align: center;
      button {
        @include ButtonBold;
        &:nth-child(2) {
          @include ButtonDelete;
        }
        margin-bottom: 10px;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }
    .new-buttons {
      text-align: center;
      button {
        margin-bottom: 10px;
        margin-right: 20px;
        text-transform: uppercase;
      }
    }
    h1 {
      text-align: center;
      color: #092d4e;
      padding-bottom: 25px;
      font-size: 25px;
      @include mobile-600 {
        font-size: 18px;
      }
      @include mobile-420 {
        font-size: 14px;
      }
    }
    textarea {
      width: 100%;
    }
    .count {
      text-align: right;
      color: #092d4e;
      font-style: italic;
      line-height: 15px;
      font-size: 12px;
      padding-right: 20px;
    }
    .texta {
      background: #fff;
      border: 0.3px solid #092d4e;
      border-radius: 9.06049px;
      padding: 0 10px;
      margin-bottom: 30px;
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        background: none;
      }
      input {
        padding: 10px 0;
        border-bottom: 0.5px solid #092d4e;
        font-weight: bold;
        color: #092d4e;
        font-size: 15px;
      }
      textarea {
        padding: 10px 0;
        font-size: 12px;
        line-height: 15px;
        color: #434343;
        background: none;
      }
    }
    .textax {
      background: #fff;
      border: 0.3px solid #092d4e;
      border-radius: 9.06049px;
      padding: 0 10px;
      margin-bottom: 30px;
      &.dif {
        border-radius: 5px;
        margin-bottom: 10px;
      }
      &.dow {
        margin-bottom: 30px;
      }
      input,
      textarea {
        width: 100%;
        border: none;
        outline: none;
        background: none;
      }
      input {
        padding: 10px 0;
        font-size: 12px;
        line-height: 15px;
        color: #434343;
        background: none;
      }
      textarea {
        padding: 10px 0;
        font-size: 12px;
        line-height: 15px;
        color: #434343;
        background: none;
      }
    }
    .proofs {
      .card {
        padding-bottom: 10px;
        input,
        select {
          width: 100%;
          padding: 12px 10px;
          font-weight: 400;
          font-size: 10.678px;
          line-height: 24px;
          color: #424b5a;
          border: 1px solid #424b5a;
          border-radius: 3px;
          outline: none;
          position: relative;
          display: inline-block;
          background-color: #fff;
          &.active {
            border: 1px solid red;
          }
          &::placeholder {
            opacity: 0.5;
          }
          @include Pop;
        }
        input {
          width: calc(100% - 20px);
        }
        &.rig {
          display: flex;
          align-items: center;
          border: 1px solid #424b5a;
          border-radius: 3px;
          padding: 12px 10px;
          margin-bottom: 20px;
          &.active {
            border: 1px solid red;
          }
          .butz {
            background: #6c757d;
            border-radius: 4px;
            width: 101px;
            height: 38px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-right: 15px;
            color: #fff;
            svg {
              margin-right: 8px;
            }
            p {
              font-size: 13px;
            }
          }
          input {
            display: none;
          }
          .sel {
            font-size: 13px;
            line-height: 24px;
            text-align: center;
            color: $main-color;
          }
        }
      }
    }
    img {
      width: 150px;
    }
  }
}

@mixin mobile-340 {
  @media (max-width: $mobile-340) {
    @content;
  }
}
@mixin mobile-420 {
  @media (max-width: $mobile-420) {
    @content;
  }
}
@mixin mobile-600 {
  @media (max-width: $mobile-600) {
    @content;
  }
}
@mixin mobile-500 {
  @media (max-width: $mobile-500) {
    @content;
  }
}
@mixin mobile-700 {
  @media (max-width: $mobile-700) {
    @content;
  }
}
@mixin mobile-800 {
  @media (max-width: $mobile-800) {
    @content;
  }
}
@mixin mobile-1024 {
  @media (max-width: $mobile-1024) {
    @content;
  }
}
@mixin mobile-1240 {
  @media (max-width: $mobile-1240) {
    @content;
  }
}
