@import './resource';
* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
button {
  cursor: pointer;
}
a {
  text-decoration: none;
}
.loader {
  text-align: center;
  padding-top: 40px;
  img {
    width: 100px;
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
