@import '../../resource';
.auth {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 819px;
}
.register {
  height: 100vh;
  max-width: 1240px;
  width: 100%;
  @include Pop;
  @include mobile-800 {
    flex-wrap: wrap;
  }
  background-color: #041c44;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    height: 100vh;
    overflow-y: auto;
    // width: min(100%, 500px);
    &.dif {
      justify-content: unset;
      padding-top: 150px;
      height: unset;
    }
    &.early {
      width: 60%;
      @include mobile-800 {
        width: 100%;
      }
    }
    @include mobile-800 {
      width: 100%;
      padding-top: 100px;
    }
    @include mobile-600 {
      padding: 0 20px;
    }
    .left-top {
      margin-left: -50px;
      padding: 30px 0;
    }
    .left-body {
      text-align: center;
      h2 {
        font-weight: 700;
        font-size: 24px;
        line-height: 45px;
        color: #424b5a;
        padding-bottom: 50px;
      }
      .check {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #424b5a;
        padding-bottom: 40px;
      }
      div {
        width: 100%;
        padding-bottom: 36px;
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 78px;
          background: #041c44;
          border: 1px solid #041c44;
          box-shadow: 0px 0px 0px 4px rgba(255, 240, 0, 0.08);
          border-radius: 3.2px;
          @include Pop;
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding-right: 23px;
          }
        }
      }
    }
    form {
      padding-bottom: 30px;
      .card {
        width: 100%;
        padding-bottom: 24.5px;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #424b5a;
          padding-bottom: 8px;
        }
        input,
        textarea {
          width: 100%;
          padding: 8px 17px;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #424b5a;
          border: 1px solid $border-color;
          border-radius: 3px;
          outline: none;
          @include Pop;
          resize: none;
        }
        textarea {
          height: 64px;
          padding: 12px 17px;
        }
        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 51.952px;
          background: #041c44;
          border: 1px solid #041c44;
          box-shadow: 0px 0px 0px 4px rgba(255, 240, 0, 0.08);
          border-radius: 5.328px;
          @include Pop;
          &:disabled {
            opacity: 0.6;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding-right: 23px;
            padding-bottom: 0;
          }
        }
        span {
          color: red;
          font-size: 13px;
        }

        img {
          text-align: center;
        }
        .loader {
          text-align: center;
          img {
            width: 150px;
          }
        }
      }
      .cardd {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding-bottom: 20px;
        p {
          margin-left: 40px;
          font-size: 10.657px;
          width: fit-content;
        }
      }
      .xd {
        margin-top: -15px;
        text-align: right;
        @include Pop;
        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          cursor: pointer;
          color: blue;
          text-decoration: underline;
        }
      }
      .cards {
        width: 100%;
        padding-bottom: 24.5px;
        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #424b5a;
          padding-bottom: 8px;
        }
        .input {
          width: 100%;
          border-radius: 8px;
          border: 1px solid $border-color;
          display: flex;
          align-items: center;
          overflow: hidden;
          input {
            width: calc(100% - 70px);
            padding: 8px 17px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #424b5a;
            border: none;
            background-color: #fff;
            @include Pop;
            outline: none;
          }
          svg {
            font-size: 20px;
            color: #424b5a;
            cursor: pointer;
          }
        }
        span {
          color: red;
          font-size: 13px;
        }
      }
      &.early {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .card {
          width: 48%;
          @include mobile-700 {
            width: 100%;
          }
          &.early {
            width: 100%;
            .buttons {
              display: flex;
              justify-content: center;
              button {
                max-width: 500px;
              }
            }
          }
        }
      }
    }
    .left-tag {
      text-align: center;
      margin-top: -14px;
      margin-bottom: 30px;
      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #424b5a;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .right {
    width: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    @include mobile-800 {
      display: none;
      width: 100%;
      margin-top: 50px;
    }
    @include mobile-600 {
      padding: 100px 20px 50px 20px;
    }
    img {
      width: 100%;
    }
  }
}
.email-page {
  background: #041c44;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @include Pop;
  .cover {
    background-color: #fff;
    width: 90%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    .dv {
      text-align: center;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include mobile-800 {
        width: 90%;
      }
      .up {
        background: #ffffff;
        border: 1px solid rgba(19, 19, 19, 0.25);
        border-radius: 3px;
        width: 650px;
        height: 373px;

        @include mobile-800 {
          width: 100%;
        }
        .image {
          padding-top: 53.8px;
          padding-bottom: 70px;
          display: flex;
          justify-content: center;
          @include mobile-800 {
            padding: 40px 0;
          }
        }
        .texts {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          h2 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 45px;
            color: #424b5a;
            @include mobile-800 {
              line-height: unset;
              padding-bottom: 10px;
            }
          }
          p {
            width: 100%;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #424b5a;
            max-width: 480px;
            padding-bottom: 103px;
            @include mobile-800 {
              padding-bottom: 30px;
            }
          }
        }
      }
      .buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        padding-top: 30px;
        button {
          width: 100%;
          max-width: 550px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 78px;
          background: #041c44;
          border: 1px solid #041c44;
          box-shadow: 0px 0px 0px 4px rgba(255, 240, 0, 0.08);
          border-radius: 3.2px;
          @include Pop;
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding-right: 23px;
          }
        }
      }
    }
  }
}
.final {
  display: flex;
  height: 100vh;
  @include Pop;
  position: relative;
  .left {
    width: 30%;
    height: 100vh;
    background-color: #041c44;
    overflow-y: auto;
    @include mobile-1024 {
      left: -100%;
      position: absolute;
      width: min(100%, 300px);
      &.show {
        left: 0;
      }
    }
    .top {
      margin-top: 41px;
      background: #d1e2fe;
      img {
        width: 200px;
      }
    }
    .drop {
      padding: 100px 20px;
      .card {
        display: flex;
        justify-content: space-between;
        .le {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 30%;
        }
        .ri {
          width: 65%;
          h2 {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
            padding-bottom: 8px;
          }
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }
    }
  }
  .right {
    width: 70%;
    height: 100vh;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    overflow-y: auto;
    @include mobile-1024 {
      width: 100%;
    }
    .cover {
      width: 100%;
      max-width: 644px;
      padding-top: 30px;
      @include mobile-1024 {
        max-width: 900px;
      }
      .bars {
        display: none;
        @include mobile-1024 {
          display: block;
        }
        svg {
          font-size: 25px;
          cursor: pointer;
          color: #041c44;
        }
      }
      .top {
        text-align: center;
        padding-bottom: 20px;
        h2 {
          font-weight: 700;
          font-size: 18px;
          line-height: 45px;
          color: #424b5a;
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #424b5a;
        }
      }
      .form {
        .form-body {
          padding-bottom: 25px;
          .tag {
            background: #041c44;
            padding: 24px 50px;
            margin-bottom: 20px;
            p {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
            }
          }
          .card {
            width: 100%;
            padding-bottom: 10px;
            p {
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #424b5a;
              padding-bottom: 8px;
            }
            input,
            select {
              width: calc(100% - 34px);
              padding: 0px 17px;
              height: 60px;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #424b5a;
              border: 1px solid #424b5a;
              border-radius: 3px;
              outline: none;
              &.active {
                border: 1px solid red;
              }
              &:disabled {
                opacity: 0.6;
              }

              @include Pop;
            }
            select {
              width: 100%;
              option {
                text-transform: capitalize;
              }
            }
            svg {
              color: red;
              margin-top: 10px;
              font-size: 25px;
            }
            span {
              color: red;
              font-size: 12px;
            }
            .anothes {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;
              cursor: pointer;
              p {
                padding-left: 10px;
              }
              div {
                display: flex;
                align-items: center;

                &.addx {
                  svg {
                    color: #0b63f8;
                    font-size: 12px;
                    margin-top: 0;
                  }
                  p {
                    color: #0b63f8;
                    font-size: 12px;
                    padding-bottom: 0;
                  }
                }
                &.remove {
                  svg {
                    color: red;
                    font-size: 12px;
                    margin-top: 0;
                  }
                  p {
                    color: red;
                    font-size: 12px;
                    padding-bottom: 0;
                  }
                }
              }
            }
          }
          .cardt {
            width: 100%;
            padding-bottom: 48px;
            h3 {
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;
              color: #000000;
              padding-bottom: 13px;
            }
            p {
              font-size: 16px;
              line-height: 24px;
              color: #424b5a;
            }
          }
          .cardX {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;
            .lef {
              width: 45%;
              h4 {
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #424b5a;
              }
              p {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #424b5a;
              }
            }
            .rig {
              width: 50%;
              display: flex;
              align-items: center;
              border: 1px solid rgba(19, 19, 19, 0.25);
              border-radius: 3px;
              padding: 11px 17px;
              &.active {
                border: 1px solid red;
              }
              .butz {
                background: #6c757d;
                border-radius: 4px;
                width: 101px;
                height: 38px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-right: 15px;
                color: #fff;
                svg {
                  margin-right: 8px;
                }
              }
              input {
                display: none;
              }
              .sel {
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                color: #424b5a;
              }
            }
          }
          .cardY {
            padding: 10px 50px;
            .lowx {
              display: flex;
              justify-content: space-between;
              padding-bottom: 10px;
              .ld {
                width: 50%;
                p {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  color: #424b5a;
                }
              }
              .rd {
                width: 50%;
                h3 {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 24px;
                  color: #424b5a;
                  &.country {
                    text-transform: capitalize;
                  }
                }
              }
            }
          }
          .cardU {
            padding: 25px 50px;
            .lowx {
              display: flex;
              padding-bottom: 10px;
              .ld {
                font-size: 25px;
                svg {
                  color: #212529;
                }
              }
              .rt {
                padding-left: 30px;
                h3 {
                  font-weight: 700;
                  font-size: 16px;
                  color: #424b5a;
                }
              }
            }
          }
          .add {
            text-align: right;
            margin-top: -30px;
            margin-bottom: 45px;
            button {
              padding: 7px 15px;
              color: #fff;
              background-color: darkgreen;
              border-radius: 3.2px;
              border: none;
              @include Pop;
              font-size: 10px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          padding-bottom: 40px;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            background: #041c44;
            border: 1px solid #343a40;
            box-shadow: 0px 0px 0px 4px rgba(130, 138, 145, 0.75);
            border-radius: 3.2px;
            padding: 15px;
            width: fit-content;
            @include Pop;

            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              padding-right: 12px;
            }

            &:nth-child(1) {
              margin-right: 60px;
              background: #a5d7a7;
              p {
                padding-left: 12px;
              }
            }
            @include mobile-500 {
              width: 100%;
              &:nth-child(1) {
                margin-right: 0;
                margin-bottom: 20px;
              }
            }
          }
        }
      }
    }
  }
}
.verification {
  display: flex;
  justify-content: center;
  background-color: #041c44;
  height: 100vh;
  align-items: center;
  overflow: hidden;
  .cover {
    width: min(600px, 100%);
    background-color: #fff;
    position: relative;
    height: 100%;
    .top {
      background: #d1e2fe;
      text-align: center;
      display: flex;
      justify-content: center;
      width: 100%;
      img {
        width: 200px;
      }
    }
    .rest {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @include Pop;
      .center {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
          font-size: 200px;
          &.suc {
            color: green;
            margin-bottom: 20px;
          }
          &.fail {
            color: darkred;
            margin-bottom: 20px;
          }
        }
        h1.suc {
          font-size: 20px;
        }
      }
    }
  }
}
