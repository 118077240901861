.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 100%;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 100%;
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 100%;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 100%;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

.navbar-landing {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 40;
  display: block;
  width: 100%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
}

.navbar-landing nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
}

@media (min-width: 1024px) {
  .navbar-landing {
    border-style: none;
    background-color: transparent;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }

  .navbar-landing nav {
    flex-wrap: nowrap;
  }
}

.navbar-landing nav .logo {
  display: inline-block;
  white-space: nowrap;
}

.navbar-landing nav .navbar-landing-nav {
  margin-top: 1rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}

@media (min-width: 1024px) {
  .navbar-landing nav .navbar-landing-nav {
    margin-top: 0;
    flex-direction: row;
    align-items: center;
  }
}

.navbar-landing nav .navbar-landing-nav .nav-item .nav-link {
  /* position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: .375rem;
  background-color: transparent;
  padding: .25rem .875rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  letter-spacing: .025em;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: .3s */

  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.375rem;
  background-color: transparent;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.025em;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.navbar-landing nav .navbar-landing-nav .active .nav-link,
.navbar-landing nav .navbar-landing-nav .nav-item .nav-link:active,
.navbar-landing nav .navbar-landing-nav .nav-item .nav-link:focus,
.navbar-landing nav .navbar-landing-nav .nav-item .nav-link:hover {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}

.navbar-landing nav .nav-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.navbar-landing.nav-light .logo .logo-light {
  display: none;
}

.navbar-landing.nav-light .logo .logo-dark {
  display: block;
}

.navbar-landing.nav-light .navbar-landing-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.navbar-landing.nav-light .navbar-landing-nav .nav-item.active .nav-link {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  .navbar-landing.nav-light .logo .logo-light {
    display: block;
  }

  .navbar-landing.nav-light .logo .logo-dark {
    display: none;
  }

  .navbar-landing.nav-light .navbar-landing-nav .nav-item .nav-link {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  .navbar-landing.nav-light .navbar-landing-nav .nav-item.active .nav-link {
    background-color: rgb(255 255 255 / 0.1);
  }
}

.navbar-landing.nav-dark nav .logo .logo-light {
  display: none;
}

.navbar-landing.nav-dark nav .logo .logo-dark {
  display: block;
}

.navbar-landing.nav-dark .navbar-landing-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.navbar-landing.nav-dark .navbar-landing-nav .nav-item.active .nav-link {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}

.navbar-landing.nav-sticky {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
}

.navbar-landing.nav-sticky nav .logo .logo-light {
  display: none;
}

.navbar-landing.nav-sticky nav .logo .logo-dark {
  display: block;
}

.navbar-landing.nav-sticky.nav-light .navbar-landing-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.navbar-landing.nav-sticky.nav-light .navbar-landing-nav .nav-item .nav-link:active,
.navbar-landing.nav-sticky.nav-light .navbar-landing-nav .nav-item .nav-link:focus,
.navbar-landing.nav-sticky.nav-light .navbar-landing-nav .nav-item .nav-link:hover {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}

.navbar-landing.nav-sticky.nav-light .navbar-landing-nav .nav-item.active .nav-link {
  background-color: rgb(148 163 184 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}

.overflow-hidden {
  overflow: hidden;
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill,
    stroke, opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[height\] {
  transition-property: height;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-\[opacity\] {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.duration-200 {
  transition-duration: 0.2s;
}

.duration-300 {
  transition-duration: 0.3s;
}

.duration-500 {
  transition-duration: 0.5s;
}

.duration-700 {
  transition-duration: 0.7s;
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.after\:transition-all::after {
  content: var(--tw-content);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.lg\:basis-auto {
  flex-basis: auto;
}

/* .swiper {
  width: 100%;
  height: 100%;
} */

.open.hs-overlay-open\:mt-7 {
  margin-top: 1.75rem;
}

.open.hs-overlay-open\:flex {
  display: flex;
}

.open.hs-overlay-open\:opacity-100 {
  opacity: 1;
}

.open.hs-overlay-open\:duration-500 {
  transition-duration: 500ms;
}

.open .hs-overlay-open\:mt-7 {
  margin-top: 1.75rem;
}

.open .hs-overlay-open\:flex {
  display: flex;
}

.open .hs-overlay-open\:opacity-100 {
  opacity: 1;
}

.open .hs-overlay-open\:duration-500 {
  transition-duration: 500ms;
}

.navbar {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 40;
  display: block;
  width: 100%;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-family: 'Nunito', sans-serif;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
@media (min-width: 1024px) {
  .navbar {
    border-style: none;
    background-color: transparent;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
      var(--tw-shadow);
  }
}
.navbar nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
@media (min-width: 1024px) {
  .navbar nav {
    flex-wrap: nowrap;
  }
}
.navbar nav .logo {
  display: inline-block;
  white-space: nowrap;
}
.navbar nav .navbar-nav {
  margin-top: 1rem;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
@media (min-width: 1024px) {
  .navbar nav .navbar-nav {
    margin-top: 0px;
    flex-direction: row;
    align-items: center;
  }
}
.navbar nav .navbar-nav .nav-item .nav-link {
  position: relative;
  display: inline-block;
  cursor: pointer;
  border-radius: 0.375rem;
  background-color: transparent;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 0.025em;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}
.navbar nav .navbar-nav .nav-item .nav-link:active,
.navbar nav .navbar-nav .nav-item .nav-link:focus,
.navbar nav .navbar-nav .nav-item .nav-link:hover {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
.navbar nav .navbar-nav .active .nav-link {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
.navbar nav .nav-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.navbar.nav-light .logo .logo-light {
  display: none;
}
@media (min-width: 1024px) {
  .navbar.nav-light .logo .logo-light {
    display: block;
  }
}
.navbar.nav-light .logo .logo-dark {
  display: block;
}
@media (min-width: 1024px) {
  .navbar.nav-light .logo .logo-dark {
    display: none;
  }
}
.navbar.nav-light .navbar-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .navbar.nav-light .navbar-nav .nav-item .nav-link {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
.navbar.nav-light .navbar-nav .nav-item.active .nav-link {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .navbar.nav-light .navbar-nav .nav-item.active .nav-link {
    background-color: rgb(255 255 255 / 0.1);
  }
}
.navbar.nav-light .nav-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
@media (min-width: 1024px) {
  .navbar.nav-light .nav-btn {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: rgb(0 123 255 / var(--tw-text-opacity));
  }
}
.navbar.nav-dark nav .logo .logo-light {
  display: none;
}
.navbar.nav-dark nav .logo .logo-dark {
  display: block;
}
.navbar.nav-dark .navbar-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.navbar.nav-dark .navbar-nav .nav-item.active .nav-link {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
.navbar.nav-dark .nav-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.navbar.nav-sticky {
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
    var(--tw-shadow);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}
.navbar.nav-sticky nav .logo .logo-light {
  display: none;
}
.navbar.nav-sticky nav .logo .logo-dark {
  display: block;
}
.navbar.nav-sticky.nav-light .navbar-nav .nav-item .nav-link {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}
.navbar.nav-sticky.nav-light .navbar-nav .nav-item .nav-link:active,
.navbar.nav-sticky.nav-light .navbar-nav .nav-item .nav-link:focus,
.navbar.nav-sticky.nav-light .navbar-nav .nav-item .nav-link:hover {
  background-color: rgb(156 163 175 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
.navbar.nav-sticky.nav-light .navbar-nav .nav-item.active .nav-link {
  background-color: rgb(148 163 184 / 0.1);
  --tw-text-opacity: 1;
  color: rgb(0 123 255 / var(--tw-text-opacity));
}
.navbar.nav-sticky.nav-light .nav-btn {
  --tw-bg-opacity: 1;
  background-color: rgb(0 123 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
